<template>
    <div class="mt-6 mx-6">
        <p class="is-title is-size-4 mb-3">Users</p>
        
        <div v-if="false">
            <p> l o a d ing </p>
        </div>
        <div v-else>
            <div class="box" v-for="user in users" :key="user.id">
              <div class="is-flex is-flex-direction-row">
                <p class="column is-text">{{userEmail(user)}}</p>
                <button v-if="userPermissions >= 2 && user.permission_level < userPermissions" class="delete is-pulled-right is-align-self-center" @click="RemoveUser(user)">Delete</button>
              </div>
            </div>
        </div>

        <b-modal
        :visible="showModal"
        heading="Deleting User"
        :subHeading="
          'Are you sure you want to delete this user?\n' +
          selectedUser.email
        "
        confirmText="Delete"
        colorStyle="danger"
        @cancel="showModal = false"
        @confirm="ForceDeleteUser"
      />
    </div>
</template>
<script>
import BModal from '../../components/b-modal.vue';
export default {
  data(){
    return{
      showModal: false,
      selectedUser:{}
    }
  },
  components:{BModal},
  mounted() {
    //console.log("requesting the users");
    this.$store.dispatch("users/getUsers");
  },
  computed: {
    users() {
      var users = this.$store.state.users.all;
      if(users && users.items){
        //console.log(users.items);
        var curId = this.$store.state.user_id;
        return users.items.filter(function(u){
         return u.id != curId
        }).sort((a,b)=> a.email > b.email ? 1 : -1);
      }
      return []
    },
    userPermissions(){
      return this.$store.state.user_permissions;
    }
  },
  methods:{
    userEmail(user){
      if(user.permission_level == 3) return "***"+user.email+"***";
      else if(user.permission_level == 2) return "*"+user.email+"*";
      else return user.email;
    },
    RemoveUser(user){
      this.selectedUser = user;
      this.showModal = true;
    },
    ForceDeleteUser(){
      this.$store.dispatch('users/destroy', this.selectedUser);
      this.showModal = false;
    }
  }
};
</script>