<template>
  <div class="columns is-multiline mt-6" v-if="loggedIn">
    <div
      class="column is-2 mt-5 mx-2 is-pulled-left"
    >
      <div class="menu">
        <p class="menu-label is-text">Administration</p>
        <p class="is-title">{{userEmail(curUser)}}</p>
        <ul class="menu-list column">
          <li :class="{disabled: userPermissions != 3}" @click="addAdminUser">
            <a class="is-text "
            style="text-decoration:none"
            :class="[userPermissions == 3 ? ['button','is-outlined', 'is-link'] : ['has-grey-text','is-greyed-out']]"
            >Add Super User</a>
          </li>
          <li :class="{disabled: userPermissions < 2}" @click="setMenu('register')">
            <a
              class="is-text"
              style="text-decoration:none"
              :class="[userPermissions >= 2 ? ['button','is-outlined', 'is-link'] : ['has-grey-text','is-greyed-out']]"
              >Add Admin User</a
            >
          </li>
          <li>
            <a
              @click="setMenu('users')"
              class="is-text button is-outlined is-link"
              style="text-decoration:none"
              >Users</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="column is-pulled-right">
      <!-- <div class="column"> -->
      <Users v-if="menu == 'users'" />
      <Register
        :permission_level="pLevel"
        v-if="menu == 'register'"
        @registeredUser="menu = 'users'"
      />
    </div>
  </div>
</template>

<script>
import Users from "../views/users/Users.vue";
import Register from "../views/Register.vue";

export default {
  components: { Users, Register },
  data() {
    return {
      menu: "users",
      pLevel: 0,
      curUser:{}
    };
  },
  async mounted(){
    var active = this.$store.state.users.active;
    if(active && active.id == this.$store.state.user_id){
      //console.log("active", active)
      this.curUser = active;
    }
    else this.curUser = await this.$store.dispatch("users/getUser", this.$store.state.user_id)
  },
  methods: {
    setMenu: function (option) {
      this.pLevel = 0;
      this.menu = option;
    },
    addAdminUser() {
      this.pLevel = 2;
      this.menu = "register";
    },
    userEmail(user){
      if(user){
        if(user.permission_level == 3) return "***"+user.email+"***";
        else if(user.permission_level == 2) return "*"+user.email+"*";
        else return user.email;
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    userPermissions() {
      return parseInt(this.$store.state.user_permissions);
    },
  },
};
</script>
