<template>
  <div class="columns mt-6 mx-6 is-centered">
    <div class="column is-6">
      <form class="mt-6">

          <h1 class="is-title is-size-3">{{header}}</h1>
          <h1 class="is-text my-3 px-6">{{subtitle}}</h1>
        <div class="field">
          <label class="is-text label is-pulled-left">Email</label>
          <div class="control">
            <input
              class="input is-text"
              type="email"
              placeholder="e.g. alex@example.com"
              v-model="email"
            />
          </div>
        </div>

        <div class="field">
          <label class="is-text label is-pulled-left">Password</label>
          <div class="control">
            <input class="input is-text" type="password" placeholder="***6 characters or more***" v-model="password"/>
          </div>
        </div>
        <div class="columns">
            <p class="column is-pulled-right">
                <button class="button is-link is-outlined is-text is-sharp" v-on:click="signup">Sign up</button>
            </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    permission_level: {
      type: Number,
      default: 0
    }
  },
    data() {
        return {
          email:"",
          password: "",
          header:"Register New User",
          subtitle:""
        }
    },
    mounted(){
      this.header = this.permission_level >= 2 ? "Register Super User" : "Register Admin User";
      this.subtitle = this.permission_level >= 2 ? "Super Users have a permission level 2 and have one star next to their username under the admin panel.\nThese accounts can only be deleted by super admin users.\nThese accounts can make / delete Admin User accounts." 
      : "Admin Users have a permission level 0 and don’t have any stars next to their username.\nThese accounts cannot make / delete any other accounts, but can be deleted by the other accounts.";
    },
    watch:{
      permission_level(){
        this.header = this.permission_level >= 2 ? "Register Super User" : "Register Admin User";
        this.subtitle = this.permission_level >= 2 ? "Super Users have a permission level 2 and have one star next to their username under the admin panel.\nThese accounts can only be deleted by super admin users.\nThese accounts can make / delete Admin User accounts." 
      : "Admin Users have a permission level 0 and don’t have any stars next to their username.\nThese accounts cannot make / delete any other accounts, but can be deleted by the other accounts.";
      }
    },
    methods:{
        signup(){
          let data = {
            user:{
              email: this.email,
              password: this.password,
              permission_level: this.permission_level
            }
          }
            this.$store.dispatch("createUser", data).then(()=>{
              // This switches to the new user and routes to home
              // this.$store.dispatch("login", data).then(()=>{
              //   this.$router.push('/')
              // })

              this.$emit('registeredUser');
            })
             
        }
    }
};
</script>

